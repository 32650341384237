<router-outlet (activate)="hijosActivos = true" (deactivate)="hijosActivos = false; desactivarHijo()"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
  <mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
    <mat-toolbar>
      <button class="filter-button-side" mat-flat-button
        matTooltip="Ocultar filtros"
        mat-tooltip-panel-above
        (click)="filtrosSideNav.toggle()"
      >
        <mat-icon>cancel</mat-icon>
      </button>
      Filtros
    </mat-toolbar>
    <ol class="lista-filtros-side">
      <div class="div-filtros-side">
        <li class="filtro-side" *ngIf="sessionData.isSuperAdmin">
          <mat-select-search
            class="id-card-field"
            [label]="'Cliente'"
            [placeHolder]="'Selecciona un cliente'"
            [value]="'idFinanciera'"
            [labelField]="'nombre'"
            [icon]="'account_balance'"
            [options]="financieras"
            [disabled]="loadingClientes"
            [(ngModel)]="idFinanciera"
            [maxlength]="100"
            (ngModelChange)="onChangeLender($event)"
          >
          </mat-select-search>

        </li>
      </div>

      <button
      class="boton-accion-front"
        style="width: 100%;"
        mat-stroked-button
        color="primary"
        (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos; filtrar();">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
      </button>
      <button
        style="width: 100%; margin-top: 10px;"
        mat-stroked-button
        (click)="limpiarFiltros()">
          <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
          Limpiar Filtros
      </button>
    </ol>
  </mat-sidenav>

  <div class="button-container-wrapper button-container">
    <div class="button-toggle-group-container">
      <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
        <mat-button-toggle value="filtros"
            (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
            matTooltip="Filtrar"
            *ngIf="sessionData.isSuperAdmin"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">filter_alt</mat-icon>
            <span *ngIf="filtros.financiera" class="notification-dot-filtros notification-dot-accion">1</span>


        </mat-button-toggle>
        <mat-button-toggle value="actualizar"
          *ngIf="checklists && checklists.length > 0"
          (click)="loadChecklists()"
          matTooltip="Refrescar"
          mat-tooltip-panel-above>
          <mat-icon class="kobra-icon">sync</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="acciones"
          [matMenuTriggerFor]="menuChecklist"
          matTooltip="Acciones"
          mat-tooltip-panel-above>
          <mat-icon class="kobra-icon">more_vert</mat-icon>
        </mat-button-toggle>
        <!-- <mat-button-toggle value="ayuda"
          matTooltip="Ayuda"
          mat-tooltip-panel-above
          (click)="ayuda = !ayuda;">
          <mat-icon color="primary">help</mat-icon>
        </mat-button-toggle> -->
      </mat-button-toggle-group>
    </div>
  </div>

  <mat-menu #menuChecklist="matMenu">
    <button [disabled]="!checklistSeleccionado" mat-menu-item (click)="editChecklist(checklistSeleccionado)" matTooltip="Editar"
      matTooltipPosition="above">
      <mat-icon class="kobra-icon">edit</mat-icon>Editar
    </button>
    <button [disabled]="!checklistSeleccionado" mat-menu-item (click)="formato(checklistSeleccionado, 'formato-visita')" matTooltip="Plantillas visitas"
      matTooltipPosition="above">
      <mat-icon class="kobra-icon">description</mat-icon>Plantillas visitas
    </button>
    <button [disabled]="!checklistSeleccionado" mat-menu-item (click)="formato(checklistSeleccionado, 'formato-cuenta')" matTooltip="Plantillas cuentas"
      matTooltipPosition="above">
      <mat-icon class="kobra-icon">description</mat-icon>Plantillas cuentas
    </button>
    <button [disabled]="!checklistSeleccionado" mat-menu-item (click)="abrirLayoutEditar(checklistSeleccionado)" matTooltip="Layout"
      matTooltipPosition="above">
      <mat-icon class="kobra-icon">view_list</mat-icon>Layout importación
    </button>
    <button [disabled]="!checklistSeleccionado || !sessionData.isSuperAdmin" mat-menu-item (click)="configChecklist(checklistSeleccionado)" matTooltip="Editar"
      matTooltipPosition="above">
      <mat-icon class="kobra-icon">settings</mat-icon>Configuración
    </button>
  </mat-menu>

  <app-tabla-expandible
      [parametros]="tablaChecklist"
      [items]="checklists">

      <div t-header>
        <mat-card *ngIf="ayuda" class="cardAyuda">
          <mat-card-content>
            <p><mat-icon color="primary">help</mat-icon>
              Este módulo es para poder listar asi como editar los cuestionarios y sus distintas funciones.
            </p>
            Acciones:
            <p>
              <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda y el acceso a los cuestionarios
              ya existentes.
            </p>
            <p>
              <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios
              de filtros aplicados.
            </p>
            <p>
              <mat-icon class="kobra-icon">edit</mat-icon><b> Editar: </b> Te permite editar el cuestionario seleccionado.
            </p>
            <p>
              <mat-icon class="kobra-icon">description</mat-icon><b> Plantillas visitas: </b> Permite añadir plantillas dinamicas para las visitas.
            </p>
            <p>
              <mat-icon class="kobra-icon">description</mat-icon><b> Plantillas cuentas: </b> Permite añadir plantillas dinamicas para las cuentas.
            </p>
            <p>
              <mat-icon class="kobra-icon">view_list</mat-icon><b> Layout importación: </b> Permite añadir los campos y configuraciones para el layout de importación de este producto.
            </p>
            <p>
              <mat-icon class="kobra-icon">settings</mat-icon><b> Configuración: </b> Permite modificar la configuración del producto general.
            </p>
          </mat-card-content>
        </mat-card>
      </div>
      <ng-container t-paginator>
        <div style="height: 50px; background-color: white;">

        </div>
        <!--mat-paginator
          showFirstLastButtons
          [length]="cuentaAgrupadaPaginator.pagination?.totalCount"
          [pageIndex]="cuentaAgrupadaPaginator.pagination?.currentPage-1"
          [pageSize]="pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator-->
      </ng-container>
  </app-tabla-expandible>

  <!--mat-card class="kobra-card">

    <mat-card-content class="contenido">
      <div class="button-container">
        <div class="button-toggle-group-container">
          <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
            <mat-button-toggle value="filtros"
                (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
                matTooltip="Filtrar"
                *ngIf="sessionData.isSuperAdmin"
                mat-tooltip-panel-above>
                <mat-icon class="kobra-icon">filter_alt</mat-icon>
                <span *ngIf="filtros.financiera" class="notification-dot-filtros notification-dot-accion">1</span>


            </mat-button-toggle>
            <mat-button-toggle value="actualizar"
              (click)="loadChecklists()"
              matTooltip="Refrescar"
              mat-tooltip-panel-above>
              <mat-icon class="kobra-icon">sync</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="ayuda"
              matTooltip="Ayuda"
              mat-tooltip-panel-above
              (click)="ayuda = !ayuda;">
              <mat-icon color="primary">help</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <mat-accordion  class="example-headers-align" *ngIf="checklists.length > 0">
        <mat-expansion-panel *ngFor="let checklist of checklists; let i = index" class="expansion">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="icon-nombre">
                <mat-icon class="status-icon active" matTooltip="Activo" matTooltipPosition="above">
                  check_circle
                </mat-icon>
                <strong>
                  {{ checklist.nombre }}
                </strong>
              </div>
              <div *ngIf="checklist.usuarioCambios" style="margin-left: 20px;">
                <p class="descripcion-inactiva descripcion-larga"><b>{{checklist.usuarioCambios}}: </b>{{checklist.descripcion}}</p>
              </div>
            </mat-panel-title>
            <mat-panel-description>
              Versión {{ checklist.numVersion }} actualizada el {{ checklist.fecha | date:'dd/MM/yyyy HH:mm' }}
              <div style="margin-left: auto;">
                <button *ngIf="checklist.activo" mat-icon-button
                  [matMenuTriggerFor]="menuChecklist"
                  matTooltip="Acciones"
                  mat-icon-button
                  (click)="$event.stopPropagation();"
                  matTooltipPosition="above">
                  <mat-icon class="kobra-icon">more_vert</mat-icon>
                </button>
                <mat-menu #menuChecklist="matMenu">
                  <button *ngIf="checklist.activo" mat-menu-item (click)="editChecklist(checklist)" matTooltip="Editar"
                    matTooltipPosition="above">
                    <mat-icon class="kobra-icon">edit</mat-icon>Editar
                  </button>
                  <button *ngIf="checklist.activo" mat-menu-item (click)="formato(checklist, 'formato-visita')" matTooltip="Plantillas visitas"
                    matTooltipPosition="above">
                    <mat-icon class="kobra-icon">description</mat-icon>Plantillas visitas
                  </button>
                  <button *ngIf="checklist.activo" mat-menu-item (click)="formato(checklist, 'formato-cuenta')" matTooltip="Plantillas cuentas"
                    matTooltipPosition="above">
                    <mat-icon class="kobra-icon">description</mat-icon>Plantillas cuentas
                  </button>
                  <button *ngIf="checklist.activo" mat-menu-item (click)="abrirLayoutEditar(checklist)" matTooltip="Layout"
                    matTooltipPosition="above">
                    <mat-icon class="kobra-icon">view_list</mat-icon>Layout importación
                  </button>
                  <button *ngIf="checklist.activo && sessionData.isSuperAdmin" mat-menu-item (click)="configChecklist(checklist)" matTooltip="Editar"
                    matTooltipPosition="above">
                    <mat-icon class="kobra-icon">settings</mat-icon>Configuración
                  </button>
                </mat-menu>
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="checklist-inactivos" *ngFor="let checkInactivo of checklist.checklistsInactivos; let j = index;">
            <hr>
            <div *ngIf="!checkInactivo.activo" style="margin-left: 30px;">
              <div class="icon-nombre">
                <mat-icon class="status-icon inactive" matTooltip="Inactivo" matTooltipPosition="above">
                  highlight_off
                </mat-icon>
                <strong>
                  {{ checkInactivo.nombre }}
                </strong>
              </div>
              <p class="version-inactiva">Versión {{ checkInactivo.numVersion }} actualizada el {{ checkInactivo.fecha | date:'dd/MM/yyyy HH:mm' }}</p>
              <p class="descripcion-inactiva"><b>{{checkInactivo.usuarioCambios}}</b></p>
              <p class="descripcion-inactiva">{{checkInactivo.descripcion}}</p>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <app-loader *ngIf="cargandoChecklist" [message]="'Obteniendo cuestionarios'"></app-loader>
    </mat-card-content>
    <mat-card-footer *ngIf="loadingClientes">
      <i *ngIf="loadingClientes">Obteniendo clientes...</i>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card-footer>
  </mat-card-->
</div>
