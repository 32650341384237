import { ApplicationService } from 'src/app/application/shared/application.service';
import { Component, OnInit, Input, ViewChild, Inject, ElementRef, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmacionDialog } from 'src/app/modales-genericos/confirmacion/confirmacion-dialog.component';

@Component({
    selector: 'app-text-area-dialog',
    templateUrl: './text-area-dialog.component.html',
    styleUrls: ['./text-area-dialog.component.css']
})
export class TextAreaDialogComponent implements OnInit {
  title: string;
  subtitle: string;
  action: string;
  label: string;
  placeHolder: string;
  buttonOk: string;
  buttonOkIcon: string;
  textResponse: string;
  @ViewChild('primerControl') nombreInput: ElementRef;

  constructor(
    private app: ApplicationService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TextAreaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.subtitle = data.subtitle;
    this.action = data.action || 'boton-accion-front';
    this.label = data.label || 'Capture la información';
    this.placeHolder = data.placeHolder || '';
    this.buttonOk = data.buttonOk || 'Aceptar';
    this.buttonOkIcon = data.buttonOkIcon || 'check';
  }

  ngOnInit() {
    this.nombreInput.nativeElement.focus();
  }

  onClose(){
    this.dialogRef.close();
  }

  save(){
    this.dialogRef.close(this.textResponse);
  }
}
