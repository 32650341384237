import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environmentSelector } from '../../../../environments/environment.selector';
import { ServicesGeneralService } from '../servicio-general/servicio-general.service';
import { ConfiguracionAgente } from '../../../agent/modelos/configuracion-agente.model';
@Injectable({
  providedIn: 'root'
})
export class ServicesGestoresService extends ServicesGeneralService {
  protected override propertie: string = 'gestores';
  protected override baseURL: string = environmentSelector().kobraServices.gestores;

  public getEstados(): Observable<any> {
    return this.get('/estados')
      .pipe(
        map((res) => {
          return res.data as any;
        })
      );
  }

  public getMunicipios(estadoId: number): Observable<any> {
    return this.get(`/estados/${estadoId}/municipios`)
      .pipe(
        map((res) => {
          return res.data as any;
        })
      );
  }

  public getColonias(municipioId: number, codigoPostal: string): Observable<any> {
    return this.get(`/municipios/${municipioId}/colonias?codigoPostal=${codigoPostal}`)
      .pipe(
        map((res) => {
          return res.data as any;
        })
      );
  }

  public getCodigoPostales(estadoId: number ,municipioId: number): Observable<any> {
    return this.get(`/estados/${estadoId}/municipios/${municipioId}/codigopostal`)
      .pipe(
        map((res) => {
          return res.data as any;
        })
      );
  }

  public getDomicilio(agenteId: number): Observable<any> {
    return this.get(`/agentes/${agenteId}/domicilio`)
      .pipe(
        map((res) => {
          return res.data as any;
        })
      );
  }

  public getLimiteCuentasApartadas(agenteId: number): Observable<any> {
    return this.get(`/agentes/${agenteId}/limite-cuentas`)
      .pipe(
        map((res) => {
          return ConfiguracionAgente.map(res.data);
        })
      );
  }

  public saveLimiteCuentasApartadas(params: any): Observable<any>{
    return this.patch(`/agentes/${params.idAgente}/limite-cuentas/${params.valor}`, {})
      .pipe(
        map((res) => {
          return res.data as any;
        })
      );
  }

  public saveDomicilio(agenteId: number, domicilio: object): Observable<any> {
    return this.patch(`/agentes/${agenteId}/domicilio`, domicilio)
      .pipe(
        map((res) => {
          return res.data as any;
        })
      );
  }

  public getHistorial(idCobrador:number, query: string){
    return this.get(`/agentes/${idCobrador}/recorrido${query}`)
      .pipe(
        map((res) => {
          return res.data as any;
        })
      );
  }

  public descargarHistorialUbicaciones(idCobrador:number, query: string){
    return this.get(`/agentes/${idCobrador}/recorrido/descargar${query}`);
  }
}
