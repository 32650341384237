import { Loading } from './../../../../application/loaders/shared/loading';
import { Component, OnInit, ViewChild, EventEmitter, Inject } from '@angular/core';
import { ApplicationService } from '../../../../application/shared/application.service';
import { environmentSelector } from '../../../../../environments/environment.selector';
import { UploadFileComponent } from '../../../../shared/components/upload-file-v2/upload-file.component';
import { VisitService } from "../../../../visit/visit.service";
import { take } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cargar-archivo-word',
  templateUrl: 'cargar-archivo-word.component.html',
  styleUrls: ['cargar-archivo-word.component.css'],
  providers: [VisitService]
})
export class ModalCargarArchivoWordComponent implements OnInit {
  private environment = environmentSelector();

  @ViewChild(UploadFileComponent) uploadFileComponent: UploadFileComponent;
  
  acceptFiles: string = '.docx';
  file: File[] = [];
  urlHelp: string = this.environment.kobraHelp;
  isFile: boolean = false;
  descripcion: string = '';

  constructor(
    public dialogRef: MatDialogRef<ModalCargarArchivoWordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private app: ApplicationService, 
    private visitService: VisitService
  ) {
    this.file = [];
  }

  ngOnInit() {}

  private isFileValid(): boolean {
    if(!this.file.length) {
      this.showSnackbar("Aviso", "Favor de seleccionar un archivo.", "warning");
      return false;
    }

    const extPermitida = /(.docx)$/i;
    if(!extPermitida.exec(this.file[0].name)) {
      this.showSnackbar("Aviso", 'Sólo se permiten archivos con extensión ".docx"', "warning");
      return false;
    }

    if(!this.descripcion) {
      this.showSnackbar("Aviso", "Favor de colocar la descripción del archivo.", "warning");
      return false;
    }

    return true;
  }

  private showSnackbar(title: string, message: string, type: string) {
    this.app.showSnackbar(title, message, 3000, type);
  }

  public subirFormato(event) {
    if(!this.isFileValid()) return;

    const loading: number = this.app.showLoading('Guardando información...');
      this.visitService.subirFormatoVisita(this.file, this.data.idCliente, this.data.idFinaciera, this.data.idChecklist)
        .pipe(take(1))
        .subscribe(respuesta => this.handleUploadResponse(respuesta, loading), error => this.handleError(error, loading, null));
  }

  private handleUploadResponse(respuesta: any, loading: number) {
    if(respuesta.success) {  
      this.uploadFileComponent.removeFile();
      this.guardarFormatoVisita(respuesta.data, loading);    
      this.limpiar();
            
    } else {
      this.showSnackbar("Aviso", "Ocurrió un problema al subir el archivo.", "error");
      this.app.hideLoading(loading);
    }
  }
private handleError(error: any, loading: number, ruta: string | null) {
    if(ruta) {
      this.eliminarArchivoS3(ruta);
    }
    
    this.app.hideLoading(loading);
    this.app.showError(error);
  }
  

  public guardarFormatoVisita(data: any, loading: number) {
    this.visitService.guardarFormatoVisita(data, this.data.idCliente, this.data.idProducto, this.data.idChecklist, this.data.numVersion, this.descripcion, this.data.tipoFormato, this.data.formatoConError)
        .pipe(take(1))
        .subscribe(respuesta => this.handleSaveResponse(respuesta, loading, data.ruta), error => this.handleError(error, loading, data.ruta));
  }

  private handleSaveResponse(respuesta: any, loading:number, ruta:string) {
    
    if(respuesta.success) {   
      // Verificamos si `bloques` o `checklist` tienen datos
      const bloquesExisten = respuesta.data?.bloques?.length > 0;
      const checklistExiste = respuesta.data?.checklist?.length > 0;

      // Si `bloques` o `checklist` tienen elementos, llamamos a `variablesConfirmacion`
      if (bloquesExisten || checklistExiste) {
        this.showSnackbar("Aviso", "La platilla contiene irregularidades.", "error");
        this.eliminarArchivoS3(ruta);
      }else {
        this.showSnackbar("Aviso", "El documento se ha guardado correctamente.", "success");
      }
      this.app.hideLoading(loading);
      this.dialogRef.close(respuesta);
    } else {
      this.eliminarArchivoS3(ruta);
      this.app.hideLoading(loading);
      this.showSnackbar("Aviso", "Ocurrió un problema al subir el archivo.", "error");
    }
  }

  public eliminarArchivoS3(ruta: string) {
      this.visitService.eliminarArchivoS3(ruta)
        .pipe(take(1))
        .subscribe(respuesta => this.handleDeleteResponse(respuesta), error => this.app.showError(error));
  }

  private handleDeleteResponse(respuesta: any) {
    if(!respuesta.success) {  
      this.showSnackbar("Aviso", "Ocurrió un problema al eliminar el archivo.", "error"); 
    } 
  }

  limpiar() {
    this.descripcion = '';
  }

  salir(bol: boolean) {
    this.dialogRef.close({
      success: bol
    });
  }

  handleFiles(files: File[]) {
    this.file = files;
  }
  
}
