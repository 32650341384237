<mat-form-field style="width: 100%;">
    <mat-label>{{ label }}</mat-label>
    <div class="icon-container">
      <input 
        type="text"
        #input
        style="border: 0px"
        placeholder="{{ placeHolder }}"
        matInput textSanitizer 
        [formControl]="myControl"
        [matAutocomplete]="auto"
        [attr.maxlength]="maxlength"
        />
      <mat-icon class="clear-icon" *ngIf="hasValue() && limpiar" (click)="clearValue()">clear</mat-icon>
      
    </div>
    <mat-autocomplete #auto="matAutocomplete" style="width: 100%; display: block;" (optionSelected)="onOptionSelected($event)">
     
        <ng-container *ngIf="filteredArray && filteredArray.length > 0">
            <mat-option *ngFor="let option of filteredArray" [value]="option[labelField]">
                {{option[labelField]}}
            </mat-option>
        </ng-container>
        <ng-container *ngIf="filteredArray && filteredArray.length == 0">
            <mat-option disabled>
                No se encontraron resultados
            </mat-option>
        </ng-container>
        <ng-container *ngIf="!filteredArray || filteredArray.length == 0">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option[labelField]">
                {{option[labelField]}}
            </mat-option>
        </ng-container>
        
        <mat-option *ngIf="loading" disabled class="kobra-search">
            <app-loader></app-loader>
        </mat-option>
    </mat-autocomplete>
    
    <mat-icon  matSuffix>{{ icon }}</mat-icon>
    <mat-error *ngIf="error">{{ errorMessage }}</mat-error>
    <mat-progress-bar mode="indeterminate" *ngIf="loader"></mat-progress-bar>
</mat-form-field>