import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { User } from "../user/user.model";
import { UserService } from "../user/user.service";
import { FormControl, FormGroupDirective, NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormValidation } from 'src/app/core/form-validation/form-validation';
import { ServiciosKobraService } from '@servicios/servicios-kobra.service';
import { AuthService } from './auth.service';
import { environmentSelector } from '../../environments/environment.selector';
import { currentEnvironment } from '../../environments/current/current-environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { TransitionService } from './transition-service.component';
import { ApiKobraService } from '../core/http/api-kobra/api-kobra.service';
import { MobileKobraService } from '../core/http/mobile-kobra/mobile-kobra.service';
import { ServicesKobraService } from '../core/http/services-kobra/services-kobra.service';
import { ServiciosBiService } from '../core/http/servicios-bi/servicios-bi.service';
import { StorageKobraService } from '../core/http/storage-kobra/storage-kobra.service';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';
import { ServicesGestoresService } from '@servicesGestores/services-gestores.service';
import { ServiciosSaldosKobraService } from '../core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { CuentaService } from '../cuentas/shared/cuenta.service';
import { LayoutService } from '../layout/servicios/layout.service';
import { ClientService } from '../core/http/client/client.service';
import { NotificationService } from '../dashboard-main/notification.service';


declare var $: any;


@Component({
  // moduleId: module.id,
  selector: 'auth',
  templateUrl: 'auth.component.html',
  styleUrls: ['auth.component.css'],
  providers: [
    UserService,
    LayoutService,
    UserService,
    ServiciosKobraService,
    CuentaService
  ],
})


export class AuthComponent implements OnInit, OnDestroy {
  public user: User;
  public message: string;
  public loading: boolean;

  public form: FormGroup;
  public correoVld: FormValidation;
  public contrasenaVld: FormValidation;
  public hide = true;
  public copyright=new Date();
  public year = 2020;
  public mostrarCaptcha = false;
  private respuestaIPBounded: Function;
  private respuestaPaisBounded: Function;
  private actualizarAlLoguear = false;
  sessionData: SessionData;
  transitionEffect = false;

  public paises = currentEnvironment.paises;
  public ambiente = environmentSelector();
  public paisActual = currentEnvironment.paises[0];

  @ViewChild('recaptcha') recaptchaElement!: ElementRef;
  @ViewChild('search') searchElement!: ElementRef;

  private token: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authServ: AuthService,
    private snackBar: MatSnackBar,
    private localStorageService: LocalStorageService,
    private lService: ServiciosKobraService,
    private layoutService: LayoutService,
    private userService: UserService,
    private apiKobraService: ApiKobraService,
    private clientService: ClientService,
    private mobileKobraService: MobileKobraService,
    private servicesKobraService: ServicesKobraService,
    private serviciosBiService: ServiciosBiService,
    private storageKobraService: StorageKobraService,
    private servicioVisitas: ServicesVisitasService,
    private servicesGestoresService: ServicesGestoresService,
    private serviciosSaldosKobraService: ServiciosSaldosKobraService,
    private cuentaService: CuentaService,
    private transitionService: TransitionService,
    private notificationService: NotificationService


  ) {
    this.respuestaIPBounded = this.respuestaIP.bind(this);
    this.respuestaPaisBounded = this.respuestaPais.bind(this);

    $.get("https://api.ipify.org/", this.respuestaIPBounded);
    if(localStorage.getItem('paisSeleccionado')){
      this.asignarPaisActual(JSON.parse(localStorage.getItem('paisSeleccionado')).abreviacion, false);
      localStorage.removeItem('paisSeleccionado');
    }
    else{
      localStorage.setItem('paisSeleccionado', JSON.stringify(this.paisActual));
      setTimeout(() => {
        $.get("https://ipapi.co/json/", this.respuestaPaisBounded);
      }, 100);
    }
    this.createForm();
    this.sessionData = this.localStorageService.getSessionData();

    //this.mostrarCaptcha =  window.location.href.includes('kobra.red') && this.ambiente.production;
  }
  private asignarPaisActual(abreviacionValidar, actualizarAlLoguear = true){
    let paisActual = this.paisActual;
    this.paises.forEach(pais => {
      if(pais.abreviacion == abreviacionValidar){
        paisActual = pais;
      }
    });

    if(paisActual != this.paisActual){
      this.paisActual = paisActual;
      this.cambioPais(actualizarAlLoguear);
    }
    else{
      localStorage.setItem('paisSeleccionado', JSON.stringify(this.paisActual));
    }
  }

  respuestaPais(response){
    let abreviacionValidar = response.country.toLocaleLowerCase();
    this.asignarPaisActual(abreviacionValidar)
  }

  respuestaIP(response){
    localStorage.setItem("ipPublica", response);
  }

  ngOnInit() {
    this.year = this.copyright.getFullYear();
    this.searchElement?.nativeElement.focus();

    /*if(this.mostrarCaptcha){
      this.addRecaptchaScript();
    }*/

    this.user = new User();
    this.user.tipo = 'super_administrador';
  }

  ngOnDestroy(): void {
    /*if(this.mostrarCaptcha ){
      const recaptchajs = document.getElementById('recaptcha-jssdk');
      recaptchajs.remove();
      this.token = '';
    }*/

  }

  public createForm(): void {
    this.form = this.formBuilder.group({
      correo: ['', [Validators.required, Validators.email]],
      contrasena: ['', [Validators.required]],
    });

    this.correoVld = new FormValidation(this.form.controls['correo']);
    this.correoVld.invalidMessage = {
      required: 'Este campo es requerido'
    };
    this.correoVld.start();

    this.contrasenaVld = new FormValidation(this.form.controls['contrasena']);
    this.contrasenaVld.invalidMessage = {
      required: 'Este campo es requerido'
    };
    this.contrasenaVld.start();
  }

  public logIn(): void {
    if (this.form.valid) {
      this.loading = true;
      let usuario: User = new User();
      usuario.tipo = 'super_administrador';
      usuario.correo = this.form.value.correo;
      usuario.contrasena = this.form.value.contrasena;

      if(!this.token && this.mostrarCaptcha){
        this.loading = false;
        this.message = 'Debes resolver el captcha';
        this.snackBar.open(this.message, 'OK', {
          duration: 0,
          panelClass: ['error']
        });
        return;
      }
      if(this.loading){
        this.message = '';

        let subs = this.authServ.logIn(this.form.value.correo, this.form.value.contrasena)
        .subscribe(
          res => {
            localStorage.setItem('userID', res.data.idUsuario.toString());
            localStorage.setItem('access-token', res.data.token);
            localStorage.setItem('paisSeleccionado', JSON.stringify(this.paisActual));
            this.localStorageService.saveToken(res.data.token);
            this.sessionData = this.localStorageService.getSessionData();
            this.transitionEffect = true;
            this.router.navigate(['/']).then(()=>{
              if(this.actualizarAlLoguear){
                this.actualizarAlLoguear = false;
                window.location.reload();
              }

            });

            this.transitionEffect = false;

            this.loading = false;
            this.notificationService.initSocket();
          },
          err => {
            this.message = err;
            this.snackBar.open(this.message, 'OK', {
              duration: 5000,
              panelClass: ['error']
            });
            this.loading = false;
            subs.unsubscribe();
          },
          () => subs.unsubscribe()
        );
      }
    }
  }

  /*cerrarSesion(): void{
    this.lService.post('/logout', {})
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        localStorage.clear();
        localStorage.setItem('paisSeleccionado', JSON.stringify(this.paisActual));
        this.router.navigate(['/auth'])
    });
  }*/

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    }

    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
  }

  renderReCaptcha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : '6LehDb4ZAAAAADC7aDXsKj5wpAB31g3LimSxATZ5 ',
      'callback': (response) => {
        this.token = response;
      }
    });
  }

  cambioPais(actualizarAlLoguear = true){
    this.actualizarAlLoguear = actualizarAlLoguear;
    localStorage.setItem('paisSeleccionado', JSON.stringify(this.paisActual));
    this.authServ.reloadBaseUrl(this.paisActual.abreviacion);
    this.ambiente = environmentSelector(this.paisActual.abreviacion);
    this.userService.reloadBaseUrl(this.paisActual.abreviacion);
    this.layoutService.reloadBaseUrl(this.paisActual.abreviacion);
    this.lService.reloadBaseUrl(this.paisActual.abreviacion);
    this.apiKobraService.reloadBaseUrl(this.paisActual.abreviacion);
    this.clientService.reloadBaseUrl(this.paisActual.abreviacion);
    this.mobileKobraService.reloadBaseUrl(this.paisActual.abreviacion);
    this.servicesKobraService.reloadBaseUrl(this.paisActual.abreviacion);
    this.serviciosBiService.reloadBaseUrl(this.paisActual.abreviacion);
    this.storageKobraService.reloadBaseUrl(this.paisActual.abreviacion);
    this.servicioVisitas.reloadBaseUrl(this.paisActual.abreviacion);
    this.servicesGestoresService.reloadBaseUrl(this.paisActual.abreviacion);
    this.serviciosSaldosKobraService.reloadBaseUrl(this.paisActual.abreviacion);
    this.cuentaService.reloadBaseUrl(this.paisActual.abreviacion);
  }

  private guardarDatosSession(usuario: User): void {

  }
}
