import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

declare const jQuery: any;

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private $: any = jQuery;

  constructor(
  ) {
  }

  /**
   * Genera el formato de número para la vista.
   * @param {string|number} valor Es el valor que se va a formatear.
   * @returns devuelve el valor formateado del numero.
   * @Auth Uriel Yair Gámez Rosales
   */
  public formatearNumero(valor:string | number): string {

    //Si no es valido el numero devuelve el valor vacio.
    if(!this.isValidNumber((valor+""))){
      return ""
    }

    // Extraemos la parte entera y la parte decimal
    let [entero, decimal] = (valor+"").split('.');

    // Aplicamos el formato a la parte entera manualmente
    entero = entero.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Verificamos si existe la parte decimal
    if (decimal !== undefined) {
      return `${entero}.${decimal}`;
    }

    return entero;
  }

  /**
   * Valida si el dato es un numero.
   * @param {string|number} pNumberStr Es el valor que se va a validar.
   * @returns devuelve true si es numero valido y false si no lo es.
   * @Auth Uriel Yair Gámez Rosales
   */
  isValidNumber(pNumberStr: string): boolean {
    return /^-?\d+(\.\d+)?$/.test(pNumberStr);
  }
}
