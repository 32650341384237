<app-modal [title]="title" [subtitle]="subtitle" [custom]="true">
  <app-loader *ngIf="loading"></app-loader>

  <form *ngIf="!loading" [formGroup]="formGroup" class="form" style="margin: 0px 20px 20px 20px;">
    <mat-card class="mat-card-gris">
      <mat-card-content>
        <app-field formControlName="nombreRespuesta" placeholder="Nombre" [maxLenghtOptions]="100"></app-field>

        <app-field
          ngDefaultControl
          type="choice"
          [items]="tiposRespuestas"
          formControlName="idTipoRespuesta"
          placeholder="Tipo de respuesta"
          [required]="true"
        ></app-field>

        <app-field
          ngDefaultControl
          *ngIf="formGroup.get('idTipoRespuesta').value === 1 || formGroup.get('idTipoRespuesta').value === 2"
          type="number"
          formControlName="minCaracteres"
          [maxValue]="1999"
          placeholder="Mínimo de caracteres"
          [required]="true"
        ></app-field>

        <app-field
          ngDefaultControl
          type="number"
          *ngIf="formGroup.get('idTipoRespuesta').value === 1 || formGroup.get('idTipoRespuesta').value === 2"
          formControlName="maxCaracteres"
          [maxValue]="2000"
          placeholder="Máximo de caracteres"
          [required]="true"
        ></app-field>

        <app-loader *ngIf="cargandoMetodosPago" [message]="'Obteniendo metodos de pago'"></app-loader>

        <app-field
          ngDefaultControl
          *ngIf="formGroup.get('idTipoRespuesta').value === 9 && !accionEtiquetaVisita"
          [items]="filteredEtiquetasVisitas$ | async"
          [disabledCombo]="answerDisabled"
          [maxLenghtOptions]="100"
          type="list"
          formControlName="options"
          placeholder="Opciones"
        ></app-field>

        <mat-form-field *ngIf="formGroup.get('idTipoRespuesta').value === 9 && accionEtiquetaVisita" style="width: 100%;">
          <mat-label>Opciones</mat-label>
          <!-- <mat-chip-listbox #chipList>
            <mat-chip-option
              *ngFor="let etiqueta of etiquetasVisitas"
              [selectable]="true"
              [removable]="true"
              (removed)="removeEtiquetaVisita(etiqueta)"
            >
              {{ etiqueta.label }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-option>
            <input
              placeholder="Opciones"
              #optionsInput
              [matChipInputFor]="chipList"
              [matAutocomplete]="auto"
              [formControl]="etiquetasVisitasCtrl"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addEtiquetaVisita($event)"
            >
          </mat-chip-listbox> -->
          <mat-chip-grid #chipGrid>
            <mat-chip-row *ngFor="let etiqueta of etiquetasVisitas"
                          (removed)="removeEtiquetaVisita(etiqueta)">
                          {{ etiqueta.label }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
            <input placeholder="Opciones"
              #optionsInput
              [matChipInputFor]="chipGrid"
              [matAutocomplete]="auto"
              [formControl]="etiquetasVisitasCtrl"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addEtiquetaVisita($event)"/>
          </mat-chip-grid>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedEtiquetaVisita($event)">
            <mat-option *ngFor="let etiqueta of filteredEtiquetasVisitas$ | async" [value]="etiqueta">
              {{ etiqueta.label }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <a
          *ngIf="formGroup.get('idTipoRespuesta').value === 9 && accionEtiquetaVisita && !mostrarNuevaEtiqueta"
          (click)="mostrarNuevaEtiqueta = true"
          target="_blank"
          class="ui button"
        >
          <i class="plus icon"></i>Nueva Etiqueta
        </a>
        <ng-container *ngIf="mostrarNuevaEtiqueta">
          <app-etiquetas-visitas-form [metodoCancelarGuardarPropio]="true" (cierraComponente)="quitarEtiquetaNueva()"></app-etiquetas-visitas-form>
        </ng-container>

        <app-field
          ngDefaultControl
          type="toggle"
          formControlName="requerida"
        >
          Requerido
        </app-field>

      </mat-card-content>
    </mat-card>

    <mat-card class="mat-card-gris" *ngIf="formGroup.get('idTipoRespuesta').value === 12">
      <mat-card-header>
          <mat-card-title>
            Columnas
            <button class="boton-agregar" mat-flat-button (click)="agregarColumnas()"><mat-icon class="kobra-icon">add</mat-icon>Agregar columna</button>
          </mat-card-title>
      </mat-card-header>
      <mat-card-content>
          <mat-tab-group [(selectedIndex)]="selectedTabIndex" *ngIf="answerAqui.columnas">
            <mat-tab *ngFor="let columna of answerAqui.columnas; index as c" [label]="(c+1) + ' - ' + columna.nombre" style="overflow: hidden;">
              <mat-card-actions  align="end">
                <button mat-icon-button
                  value="Menu"
                  matTooltip="Acciones"
                  mat-tooltip-panel-above
                  [matMenuTriggerFor]="menuEditar"
                >
                  <mat-icon class="kobra-icon">more_vert</mat-icon>
                </button>
                <mat-menu #menuEditar="matMenu">
                  <button  mat-menu-item
                    matTooltip="Agregar columna antes"
                    mat-tooltip-panel-above
                    (click)="agregarColumnas(c, true)">
                    <mat-icon class="kobra-icon">arrow_back</mat-icon>
                    Añadir columna antes
                  </button>
                  <button  mat-menu-item
                    matTooltip="Agregar columna después"
                    mat-tooltip-panel-above
                    (click)="agregarColumnas(c, false)">
                    <mat-icon class="kobra-icon">arrow_forward</mat-icon>
                    Añadir columna después
                  </button>
                  <button  mat-menu-item
                    matTooltip="Agregar columna después"
                    mat-tooltip-panel-above
                    (click)="abrirDialogoCambiarIndice(c)">
                    <mat-icon class="kobra-icon">format_list_numbered</mat-icon>
                    Cambiar posición columna
                  </button>
                  <button  mat-menu-item
                    matTooltip="Eliminar"
                    mat-tooltip-panel-above
                    (click)="eliminarColumna(c)">
                    <mat-icon color="warn">delete_forever</mat-icon>
                    Eliminar
                  </button>
                </mat-menu>
              </mat-card-actions>

              <app-field
                placeholder="Capture el nombre de la columna"
                [maxLenghtOptions]="100"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="columna.nombre"
              ></app-field>

              <app-field
                ngDefaultControl
                type="choice"
                [items]="tiposRespuestasColumnas"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="columna.idTipoRespuesta"
                placeholder="Tipo de columna"
                [required]="true"
              ></app-field>

              <app-field
                ngDefaultControl
                *ngIf="columna.idTipoRespuesta === 1 || columna.idTipoRespuesta === 2"
                type="number"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="columna.minCaracteres"
                [maxValue]="1999"
                placeholder="Mínimo de caracteres"
                [required]="true"
              ></app-field>

              <app-field
                ngDefaultControl
                type="number"
                *ngIf="columna.idTipoRespuesta === 1 || columna.idTipoRespuesta === 2"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="columna.maxCaracteres"
                [maxValue]="2000"
                placeholder="Máximo de caracteres"
                [required]="true"
              ></app-field>

              <app-field
                ngDefaultControl
                *ngIf="columna.idTipoRespuesta === 9"
                [items]="filteredEtiquetasVisitas$ | async"
                [disabledCombo]="answerDisabled"
                [maxLenghtOptions]="100"
                type="list"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="columna.options"
                placeholder="Opciones"
              ></app-field>

              <app-field
                ngDefaultControl
                type="toggle"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="columna.requerida"
              >
                Requerido
              </app-field>
            </mat-tab>
          </mat-tab-group>
      </mat-card-content>
  </mat-card>

    <mat-card class="mat-card-gris" *ngIf="!nombreRequerido && formGroup.get('idTipoRespuesta').value != 12">
      <mat-card-header>
        <mat-card-title>Acciones de la respuesta
          <mat-icon
            matSuffix
            color="primary"
            style="cursor:pointer;"
            matTooltip="¿Que son las acciones?"
            (click)="ayudaAcciones = !ayudaAcciones"
            mat-tooltip-panel-above title="">help
          </mat-icon>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-card *ngIf="ayudaAcciones" class="cardAyuda">
          <mat-card-content>
            <p>
              Las acciones sirven para detonar marcas especificas durante la visita como notificar si se realizó una promesa de pagó, detectar si se tuvo contacto durante la visita, etc.
              También dichas marcas en la visita permite realizar filtros mas optimos sin necesidad de buscar los cuestionarios visita por visita en la opción de "Todas las visitas".
            </p>
            <b>¿Cómo funciona?:</b>
            <p>
                Selecciona la acción deseada para la respuesta a tú pregunta, si deseas quitar la acción de una pregunta que ya la contiene solo es necesario deseleccionar la acción que se encuentra ya seleccionada.
            </p>
          </mat-card-content>
        </mat-card>
        <mat-chip-listbox aria-label="Fish selection">
          <ng-container *ngFor="let accion of acciones">
            <mat-chip-option *ngIf="accion.id == answerAqui.idAccionRespuesta" (click)="limpiarAccion();" class="accion-seleccionada"
            matTooltip="{{accion.descripcion}}"
            mat-tooltip-panel-above
            selected>{{accion.name}}</mat-chip-option>
            <mat-chip-option *ngIf="accion.id != answerAqui.idAccionRespuesta && validarNoAccion(accion)" (click)="asignarAccion(accion);"
            matTooltip="{{accion.descripcion}}"
            mat-tooltip-panel-above
            >{{accion.name}}</mat-chip-option>
          </ng-container>
        </mat-chip-listbox>
      </mat-card-content>
    </mat-card>

    <mat-accordion [multi]="false" *ngIf="formGroup.get('idTipoRespuesta').value === 9 && accionEtiquetaVisita">
      <mat-expansion-panel class="mat-card-gris" [expanded]="desplegarBlaster">
        <mat-expansion-panel-header>
          <h3>Difusiones Blasters y SMS {{answerAqui.blasters.length == 0 ? '(No se han configurado blasters)' : ''}}</h3>
        </mat-expansion-panel-header>
        <div>
          <div class="button-container">
              <div class="button-toggle-group-container right">
                <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
                  <mat-button-toggle value="agregar"
                      (click)="agregarConfig()"
                      matTooltip="Agregar difusión"
                      mat-tooltip-panel-above>
                      <mat-icon class="kobra-icon">add</mat-icon>
                  </mat-button-toggle>

                </mat-button-toggle-group>
              </div>
          </div>
          <mat-card class="ui fluid card-blaster" *ngFor="let blaster of answerAqui.blasters; let j = index;">
            <mat-card-content>
              <button mat-icon-button (click)="eliminarBlaster(j, blaster)" matTooltip="Eliminar difusión"
                class="eliminar-blaster"
                mat-tooltip-panel-above>
                <mat-icon color="warn">close</mat-icon>
              </button>
              <div [ngClass]="blaster.idTipoDifusion == 1 ? ['kobra', 'three', 'columns'] : ['kobra', 'two', 'columns']" style="margin-top: 45px">
                <div class="kobra column">
                  <mat-form-field style="width: 100%;">
                    <mat-label>Seleccione etiquetas</mat-label>
                    <mat-select
                      [(ngModel)]="blaster.respuestas"
                      (ngModelChange)="changeBlasterRequest($event, blaster);"
                      multiple
                      [ngModelOptions]="{standalone: true}"
                    >
                      <mat-option *ngFor="let etiqueta of showWhen" [value]="etiqueta">{{etiqueta.name}}</mat-option>
                    </mat-select>
                    <mat-icon matSuffix  class="icono-limpiar-solo"
                    *ngIf="blaster.respuestas && blaster.respuestas.length > 0"
                    (click)="blaster.respuestas = []">clear</mat-icon>
                  </mat-form-field>
                  <!-- <div align="left" class="limpiar-seleccion-kobra" *ngIf="blaster.respuestas && blaster.respuestas.length > 0">
                    <a (click)="blaster.respuestas = []"><i>Limpiar selección</i></a>
                  </div> -->
                </div>
                <div class="kobra column">
                  <mat-form-field>
                    <mat-label>Se va a ejecutar un</mat-label>
                    <mat-select
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="resetBlaster(blaster);"
                    [(ngModel)]="blaster.idTipoDifusion"
                    >
                      <mat-option [value]="1" >
                        Blaster
                      </mat-option>
                      <mat-option [value]="2" >
                        SMS
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="kobra column"
                  [ngClass]="blaster.idTipoDifusion == 1 ? ['kobra', 'column'] : ['kobra', 'column', 'two-colspan']"
                >
                  <app-field
                    *ngIf="blaster.idTipoDifusion == 1"
                    style="margin-left: 15px;"
                    type="string"
                    [maxLenghtOptions]="100"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="blaster.envio.id"
                    placeholder="Capture el id del blaster"
                  ></app-field>
                  <mat-form-field *ngIf="blaster.idTipoDifusion == 2">
                    <mat-label>Comentario</mat-label>
                    <textarea matInput textSanitizer  placeholder="Capture el mensaje a enviar"
                      maxlength="255"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="blaster.envio.message"
                    ></textarea>
                  </mat-form-field>
                  <!--app-field
                    *ngIf="blaster.idTipoDifusion == 2"
                    style="margin-left: 15px; width: calc(100% - 80px);"
                    type="string"
                    [maxLenghtOptions]="255"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="blaster.envio.message"
                    placeholder="Capture el mensaje a enviar"
                  ></app-field-->
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <span *ngIf="answerAqui.blasters.length != 0">NOTA: Si no se llena toda la información de la difusión esta no se guardará</span>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-card class="mat-card-gris" style="margin-top: 10px" *ngIf="formGroup.get('idTipoRespuesta').value != 12">
      <mat-card-header>
        <mat-card-title>
          Mostrar las siguientes preguntas cuando la respuesta sea
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <app-field
          ngDefaultControl
          *ngIf="!data.fromChildQuestion && (formGroup.get('idTipoRespuesta').value === 8 || formGroup.get('idTipoRespuesta').value === 9) && preguntaActual.orden < preguntas.length"
          type="radio"
          [items]="showWhen"
          formControlName="mostrarPreguntasCuando"
          placeholder=""
        ></app-field>

        <ul class="no-list" *ngIf="showWhen.length>0">
          <ng-container *ngFor="let pregunta of preguntas; index as i;">
            <li *ngIf="(!pregunta.condicionMostrar || (pregunta.condicionMostrar && pregunta.idChecklistPreguntaPadre == preguntaActual.idChecklistPreguntaCondicion)) && pregunta != preguntaActual && (formGroup.get('idTipoRespuesta').value === 8 || formGroup.get('idTipoRespuesta').value === 9) && (pregunta.orden > preguntaActual.orden || pregunta.idChecklistPreguntaPadre == preguntaActual.idChecklistPreguntaCondicion)">
              <!--div class="mat-expansion-panel">
                <div-->
                  <mat-checkbox class="kobra-checkbox" [(ngModel)]="pregunta.seleccionada" (ngModelChange)="agregarCondicionQuitarCondicion(pregunta, i)" [ngModelOptions]="{standalone: true}">{{ (i + 1) }} - {{ pregunta.nombrePregunta }}</mat-checkbox>
                  <!--span class="nombre-pregunta" mat-line>
                    {{ (i + 1) }} - {{ pregunta.nombrePregunta }}
                  </span>
                </div>
              </div-->
            </li>
          </ng-container>
        </ul>
      </mat-card-content>
    </mat-card>
  </form>

  <!--<button
    *ngIf="!loading"
    mat-stroked-button
    color="secondary"
    (click)="onClose()"
    m-actions
  >Cancelar</button>-->

  <div class="action-buttons-modal" m-actions>
    <button class="boton-cancelar" mat-flat-button (click)="onClose()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
    <button *ngIf="!loading" mat-stroked-button class="boton-accion-front" (click)="save()"mat-stroked-button><mat-icon>check</mat-icon>Aceptar</button>
  </div>


  <!--button
    *ngIf="!loading"
    mat-stroked-button
    color="primary"
    (click)="save()"
    m-actions
  >
    <ng-container *ngIf="answerAqui">Aceptar</ng-container>
  </button-->
</app-modal>
